import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ComputerIcon from '@mui/icons-material/Computer'
import {
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import Database from '../types/Database'
import { getContrastYIQ, stringToColour } from '../utils/FormatHelpers'
import { grey } from '@mui/material/colors'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

interface IDeskProps {
  uid: string | undefined
  desks: Database.Desk[] | undefined
  onDeskClicked: Function
  onDeskDeleted: Function | null
  onDeskEdit: Function | null
  selectedDesks: Database.Desk[]
  readOnly: boolean | undefined
  title: string | undefined
}

export default function ListDesks(props: IDeskProps) {
  const deskList = () => {
    var items: JSX.Element[] = []

    props?.desks?.forEach(element => {
      let selected =
        !!props.selectedDesks &&
        props.selectedDesks.length > 0 &&
        props.selectedDesks.find(
          x => x.office == element.office && x.name == element.name
        )
      items.push(
        <ListItem
          key={element.id}
          disablePadding
          sx={{
            border: '1px solid #333',
            margin: 0.3,
            padding: 0.3,
            paddingLeft: 2,
            paddingBottom: 1,
            width: '100%',
            borderRadius: 2
          }}
        >
          <Grid container padding={0}>
            <Grid item xs={10}>
              <ListItemButton
                role={undefined}
                onClick={() => props.onDeskClicked(element)}
                sx={{ padding: 0.2 }}
                dense
              >
                {!props.readOnly && (
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={!!selected}
                      tabIndex={-1}
                      disableRipple
                      color='primary'
                    />
                  </ListItemIcon>
                )}
                {props.readOnly && (
                  <ListItemIcon>
                    <ComputerIcon />
                  </ListItemIcon>
                )}
                <ListItemText
                  id={element.name}
                  primary={element.officeName + '-' + element.name}
                />
              </ListItemButton>
            </Grid>
            <Grid item xs={2}>
              {props.onDeskDeleted && (
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={e => {
                    if (props.onDeskDeleted) props.onDeskDeleted(element.id)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {props.onDeskEdit && (
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={e => {
                    if (props.onDeskEdit) props.onDeskEdit(element)
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box paddingLeft={5} display='block'>
                {element.labels &&
                  element.labels.trim().length > 0 &&
                  element.labels?.split(',').map(y => (
                    <Chip
                      label={y}
                      sx={{
                        backgroundColor: stringToColour(y ?? ''),
                        color: getContrastYIQ(stringToColour(y ?? '')),
                        margin: '1px'
                      }}
                    />
                  ))}
              </Box>
            </Grid>
          </Grid>
        </ListItem>
      )
    })

    return items
  }

  return (
    <Box>
      <Grid container spacing={2} padding={2} justifyContent='center'>
        <Grid item xs={12}>
          <Typography
            component='h1'
            variant='caption'
            sx={{ color: 'text.primary' }}
          >
            {props.title ?? 'Desks'}
          </Typography>
          <List>{deskList()}</List>
        </Grid>
      </Grid>
    </Box>
  )
}
