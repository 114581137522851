import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import db from '../db/db'
import AddBoxIcon from '@mui/icons-material/AddBox'
import DeleteIcon from '@mui/icons-material/Delete'
import { Alert, Autocomplete, Paper, ThemeProvider } from '@mui/material'
import Database from '../types/Database'
import { useRecoilState } from 'recoil'
import { sessionState } from '../state'
import theme from '../theme'
import { Resolver, useForm } from 'react-hook-form'
import EditDesks from '../pages/EditDesks'
import { useNavigate } from 'react-router'

interface IDeskProps {
  user: Database.User | null
  desk: Database.Desk | null
  onCreate: Function
  onUpdate: Function
}

export default function CreateDesk(props: IDeskProps) {
  const [session, setSession] = useRecoilState(sessionState)
  const [wait, setWait] = React.useState(false)
  const [newOffices, setNewOffices] = React.useState<string[]>([])
  var newOfficeIds: string[] | undefined = undefined

  function broofa() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }
  const [notifyUser, setNotifyUser] = React.useState<string>('')
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (wait) return
    setWait(true)
    const data = new FormData(event.currentTarget)
    const newDeskId: string = broofa()
    var findThisOffice =
      session.offices?.filter(
        x => x.name == data.get('officeName')?.toString()
      ) ?? []

    if (!props.desk) {
      console.log('add desk')
      const officeName =
        data.get('deskName')?.toString() +
        '-' +
        data.get('officeName')?.toString()
      if (newOffices.find(x => x == officeName)) {
        console.log('Already added')
        return
      }
      const newCheckList = [...newOffices, officeName]
      setNewOffices(newCheckList)
      console.log(newCheckList)

      var email = data.get('email')?.toString() ?? ''

      if (!props.user?.uid || props.user?.uid == '') {
        return
      }

      if (findThisOffice.length == 0) {
        const newOfficeId: string = broofa()

        const newOffice = {
          name: data.get('officeName')?.toString(),
          oid: newOfficeId,
          uid: props.user.uid
        }
        await db.addItem('offices', newOffice)
        const offices: Database.Office[] = session.offices ?? []
        const newOfficeList = [...offices, newOffice]
        setSession(old => ({
          ...old,
          offices: newOfficeList
        }))

        newOfficeIds = session.user?.officeids?.map(x => x) ?? []
        newOfficeIds.push(newOfficeId)

        await db.updateItem('users', props.user.id ?? 'xx', {
          officeids: newOfficeIds
        })

        const newDesk = {
          name: data.get('deskName')?.toString(),
          did: newDeskId,
          office: newOfficeId,
          officeName: data.get('officeName')?.toString(),
          uid: props.user.uid,
          labels: data.get('labels')?.toString(),
          availableTo: data.get('availableTo')?.toString()
        }
        await db.addItem('desks', newDesk)
        setNotifyUser('Created new office and desk')
      } else {
        await db.addItem('desks', {
          name: data.get('deskName')?.toString(),
          did: newDeskId,
          office: findThisOffice[0].oid,
          officeName: data.get('officeName')?.toString(),
          uid: props.user?.uid ?? 'xx',
          labels: data.get('labels')?.toString(),
          availableTo: data.get('availableTo')?.toString()
        })
      }
      setWait(false)
      data.delete('deskName')
      props.onCreate()
      setNotifyUser('Created new desk')
    } else {
      console.log('edit desk')
      await db.updateItem('desks', props.desk.id ?? 'xx', {
        name: data.get('deskName')?.toString(),
        labels: data.get('labels')?.toString(),
        availableTo: data.get('availableTo')?.toString()
      })
      setWait(false)
      props.onUpdate(props.desk.id)
      setNotifyUser('Desk changes saved')
    }
  }

  const fixDesks = async (event: React.MouseEvent<HTMLElement>) => {
    var bookings = await db.query({
      collection: 'booking'
    })

    bookings.forEach(async booking => {
      var updatedBooking = { ...booking }
      updatedBooking.did = updatedBooking.deskName
      await db.updateItem('booking', booking.id ?? '', updatedBooking)
    })
  }

  const navigate = useNavigate()
  const editOffice = (officeid: string | undefined) => {
    if (!officeid) return

    navigate('/edit-office/' + officeid, { replace: true })
  }

  const { register, handleSubmit } = useForm()

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <AddBoxIcon />
        </Avatar>
        {(!props.user?.uid || props.user?.uid == '') && (
          <Alert severity='error'>This is an error alert — check it out!</Alert>
        )}
        {!props.desk && (
          <Typography component='h1' variant='h5'>
            Create a desk
          </Typography>
        )}
        {props.desk && (
          <Typography component='h1' variant='h5'>
            Edit a desk {props.desk?.name}
          </Typography>
        )}

        {/* <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            disabled={wait}
            color='primary'
            onClick={fixDesks}
          >
            Fix Desk Invites
          </Button> */}
        <Box
          component='form'
          noValidate
          onSubmit={handleFormSubmit}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete='desk-name'
                defaultValue={props.desk?.name}
                name='deskName'
                required
                fullWidth
                id='deskName'
                label='Desk Name'
                autoFocus
              />
            </Grid>
            {!props.desk && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id='officeName'
                  freeSolo
                  options={
                    session.offices
                      ? session.offices.map(option => option.name)
                      : []
                  }
                  PaperComponent={props => (
                    <Paper
                      {...props}
                      sx={{ width: 300, backgroundColor: 'black' }}
                    />
                  )}
                  renderInput={params => (
                    <TextField
                      name='officeName'
                      {...params}
                      label='Office Name *'
                    />
                  )}
                />
              </Grid>
            )}
            {/* {props.desk && (
              <Button variant="outlined" startIcon={<DeleteIcon />} onClick={() => editOffice(props.desk?.office)}>
                {props.desk.officeName}
              </Button>
            )} */}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='labels'
                label='label1,label2,label3'
                name='labels'
                defaultValue={props.desk?.labels}
                autoComplete='labels'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='availableTo'
                label='limit to: email1, email2'
                defaultValue={props.desk?.availableTo}
                name='availableTo'
                autoComplete='availableTo'
              />
            </Grid>
          </Grid>
          {!props.desk && (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              disabled={wait}
              color='primary'
            >
              Create Desk
            </Button>
          )}
          {props.desk && (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              disabled={wait}
              color='primary'
            >
              Save Changes
            </Button>
          )}
        </Box>
        <Box padding={2}>
          {notifyUser != '' && (
            <Alert severity='success' onClose={() => setNotifyUser('')}>
              {notifyUser}
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  )
}
