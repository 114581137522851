import Wrapper from '../components/Wrapper'
import CreateDesk from '../components/CreateDesk'
import ListDesks from '../components/ListDesks'
import { getNewSession, sessionState } from '../state'
import { useRecoilState } from 'recoil'
import PersistentDrawerRight from '../components/AppBarR'
import db from '../db/db'
import Database from '../types/Database'
import { useParams } from 'react-router-dom'

const EditDesks = (props: any) => {
  const [userState, setUserState] = useRecoilState(sessionState)

  let { id } = useParams()
  const deskEditor = () => {
    var deskToEdit = userState?.desks?.find(x => x.id == id)
    if (deskToEdit) {
      return (
        <CreateDesk
          user={userState.user}
          onCreate={() => {}}
          onUpdate={async (id: string) => {
            var dbDesk = await db.getById('desks', id)
            const newDeskList = userState.desks?.filter(x => x.id != id)
            if (dbDesk) {
              newDeskList?.push(dbDesk)
            }
            setUserState(old => ({
              ...old,
              desks: newDeskList
            }))
          }}
          desk={deskToEdit}
        />
      )
    }
  }

  console.log('render')
  return (
    <>
      {/* <AppBar title='Meet for work portal' actions={[]} /> */}
      <PersistentDrawerRight></PersistentDrawerRight>
      <Wrapper>{userState.user && deskEditor()}</Wrapper>
    </>
  )
}

export default EditDesks
