import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import db from '../db/db'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { Alert, Divider, MenuItem, Select } from '@mui/material'
import Database from '../types/Database'
import { useRecoilState } from 'recoil'
import {
  findExistingBookings,
  officeDesks,
  refreshBookingsForDate,
  sessionState,
  dateRangeDates
} from '../state'
import ListDesks from './ListDesks'
import { dateOnly, fixDate } from '../utils/FormatHelpers'
import CalendarWeek from './CalendarWeek'
import theme from '../theme'
import DialogAlert from './DialogAlert'
import { grey } from '@mui/material/colors'

export default function OfficeDetail() {
  const [userState, setUserState] = useRecoilState(sessionState)
  const [busy, SetBusy] = React.useState<boolean>(false)
  const handleChange = async (e: any) => {
    if (!userState.offices) return
    const selectedOffice = userState.offices.filter(
      x => x.oid == e.target.value
    )[0]

    var newOfficeDesks = await officeDesks(selectedOffice)

    setUserState(old => ({
      ...old,
      selectedOffice: selectedOffice,
      officeDesks: newOfficeDesks
    }))

    setSelectedDesks([])
  }

  const [unitValue, setUnitValue] = React.useState<'Days' | 'Recur Weekly'>(
    'Days'
  )
  const handleUnitChange = async (e: any) => {
    const selectedUnit = e.target.value
    setUnitValue(e.target.value)

    setUserState(old => ({
      ...old,
      selectedRecurUnit: e.target.value
    }))

    var dateRangeBookins = await findExistingBookings(
      new Date(userState.selectedFromDate),
      userState.selectedRecurAmount,
      selectedUnit,
      userState.offices ?? []
    )

    setUserState(old => ({
      ...old,
      bookings: dateRangeBookins,
      selectedRecurUnit: e.target.value
    }))
  }

  const handleFromDayChange = async (e: any) => {
    refreshBookingsForDate(
      setUserState,
      userState,
      new Date(e.currentTarget.value)
    )
  }

  const handleAmountChange = async (e: any) => {
    var num = parseInt(e.currentTarget.value)
    if (Number.isNaN(num)) return Promise.resolve()
    if (num < 1) return Promise.resolve()

    setUserState(old => ({
      ...old,
      selectedRecurAmount: num
    }))

    var dateRangeBookins = await findExistingBookings(
      new Date(userState.selectedFromDate),
      num,
      userState.selectedRecurUnit,
      userState.offices ?? []
    )
    console.log('date range bookings', dateRangeBookins)

    setUserState(old => ({
      ...old,
      selectedRecurAmount: num,
      bookings: dateRangeBookins
    }))
  }

  const [selectedDesks, setSelectedDesks] = React.useState(
    [] as Database.Desk[]
  )

  const handleDeskClicked = (e: any) => {
    var newSelectedDesks: Database.Desk[] = []

    newSelectedDesks.push(e)

    setSelectedDesks(newSelectedDesks)
  }

  const officeOptions = () => {
    var items: JSX.Element[] = []

    userState?.offices?.forEach(element => {
      items.push(
        <MenuItem value={element.oid} key={element.oid}>
          {element.name}
        </MenuItem>
      )
    })

    return items
  }

  const availableDesks = () => {
    if (userState.officeDesks) {
      const datesInRange = dateRangeDates(
        new Date(userState.selectedFromDate),
        userState.selectedRecurUnit,
        userState.selectedRecurAmount
      )
      //console.log(datesInRange);
      console.log('list desks', userState.officeDesks, userState.bookings)
      return userState.officeDesks.filter(
        x =>
          !userState.bookings?.find(
            y =>
              y.did == x.did &&
              x.office == y.oid &&
              datesInRange.find(d => {
                console.log(
                  'date compare',
                  fixDate(d),
                  fixDate(y.forDate ?? new Date())
                )
                return fixDate(d) == fixDate(y.forDate ?? new Date())
              })
          ) &&
          (!x.availableTo ||
            x.availableTo.includes(userState.user?.email ?? '@#$@#$'))
      )
    }
    const d: Database.Desk[] = []
    return d
  }
  const [notifyUser, setNotifyUser] = React.useState<string>('')
  const deleteBooking = async (deleteDate: Date) => {
    const dbooking = await db.query({
      collection: 'booking',
      where: [
        ['uid', '==', userState.user?.uid],
        ['forDate', '==', deleteDate]
      ]
    })
    await db.deleteItem('booking', dbooking[0].id)

    refreshBookingsForDate(
      setUserState,
      userState,
      new Date(userState.selectedFromDate)
    )
  }

  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false)
  const confirmBookDesk = async () => {
    if (selectedDesks.length == 0) {
      setNotifyUser('No desk selected')
      return
    }
    if (userState.selectedRecurAmount > 1) {
      setConfirmOpen(true)
    } else {
      await bookDesks()
    }
  }
  const bookDesks = async () => {
    SetBusy(true)
    var newBookingsList: Database.Booking[] = []

    selectedDesks.forEach(async element => {
      // To calculate the time difference of two dates
      var d1 = new Date(userState.selectedFromDate).getTime()

      // To calculate the no. of days between two dates

      var increments = userState.selectedRecurUnit == 'Days' ? 1 : 7
      var numOfNewBookings = 0
      var numExistingBookings = 0
      for (let index = 0; index < userState.selectedRecurAmount; index++) {
        const forDate = dateOnly(new Date(userState.selectedFromDate))
        forDate.setDate(forDate.getDate() + index * increments)

        const keyDate = forDate.toISOString().slice(0, 10).replace(/-/g, '')

        const existingBooking = userState.bookings?.find(b => {
          return (
            b.uid == userState?.user?.uid &&
            b.forDate &&
            fixDate(b.forDate) == fixDate(forDate)
          )
        })
        if (existingBooking) {
          numExistingBookings++
        } else {
          var newBooking: Database.Booking = {
            bookingKey: keyDate + '-' + element.office + '-' + element.name,
            id: keyDate + '-' + element.office + '-' + element.name,
            officeBookingKey: keyDate + '-' + element.office,
            deskName: element.name,
            did: element.did,
            officeName: userState.selectedOffice?.name ?? '',
            oid: element.office,
            forDate: forDate,
            uid: userState.user?.uid,
            appver: 2,
            bookerName:
              userState.user?.firstname +
              ' ' +
              userState.user?.lastname?.substring(0, 1)
          }
          newBookingsList.push(newBooking)
          await db.addItem('booking', newBooking)
          numOfNewBookings++
        }
      }
      const currentBookings = userState.bookings ? [...userState.bookings] : []
      setUserState(old => ({
        ...old,
        bookings: currentBookings.concat(newBookingsList)
      }))
      const existNote =
        numExistingBookings > 0
          ? 'Could not add ' +
            numExistingBookings +
            ' as you already have a desk booked on that date.'
          : ''
      setNotifyUser('Added ' + numOfNewBookings + ' bookings. ' + existNote)
      setConfirmOpen(false)
    })
    SetBusy(false)
  }

  return (
    <Box>
      <Container component='main'>
        <CssBaseline />
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            sx={{
              backgroundColor: 'secondary.light',
              border: '1px solid #efefef',
              padding: '0px 20px 0px 20px',
              borderRadius: '10px',
              boxShadow: '4px 2px 2px lightgrey'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Typography
                component='h1'
                variant='h5'
                sx={{
                  borderBottom: '1px solid #efefef',
                  marginBottom: '7px',
                  width: '100%',
                  textAlign: 'center',
                  color: 'text.primary'
                }}
              >
                Book a desk
              </Typography>
              <DialogAlert
                open={confirmOpen}
                confirmLabel='Yes'
                cancelLabel='No'
                text={
                  'Are you sure you want to book ' +
                  selectedDesks[0]?.name +
                  ' for ' +
                  userState.selectedRecurAmount +
                  ' ' +
                  (userState.selectedRecurUnit == 'Days'
                    ? ' days'
                    : ' weeks on the same day') +
                  '.'
                }
                title='Are you sure?'
                onConfirm={bookDesks}
                onClose={() => setConfirmOpen(false)}
              ></DialogAlert>

              <Box component='form' noValidate>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={5}>
                    <Typography sx={{ color: 'text.primary' }}>From</Typography>
                    <TextField
                      type='date'
                      style={{ fontSize: '1.1rem' }}
                      onChange={handleFromDayChange}
                      value={userState.selectedFromDate}
                      fullWidth={true}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography sx={{ color: 'text.primary' }}>For</Typography>
                    <TextField
                      type='number'
                      style={{ fontSize: '1.1rem' }}
                      onChange={handleAmountChange}
                      onFocus={e => e.target.select()}
                      fullWidth={true}
                      value={userState.selectedRecurAmount}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={{ color: 'text.primary' }}>
                      Period
                    </Typography>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      label='Select Office'
                      fullWidth={true}
                      onChange={handleUnitChange}
                      value={userState.selectedRecurUnit}
                    >
                      {['Days', 'Repeat weekly'].map(element => (
                        <MenuItem value={element} key={element}>
                          {element}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ color: 'text.primary' }}>
                      Office
                    </Typography>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      label='Select Office'
                      fullWidth={true}
                      onChange={handleChange}
                      value={userState.selectedOffice?.oid}
                    >
                      {officeOptions()}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    {userState.bookings &&
                      userState.selectedOffice &&
                      availableDesks().length > 0 && (
                        <ListDesks
                          desks={availableDesks()}
                          uid={userState.user?.uid}
                          onDeskClicked={handleDeskClicked}
                          onDeskDeleted={null}
                          onDeskEdit={null}
                          selectedDesks={selectedDesks}
                          readOnly={false}
                          title='Select a desk'
                        />
                      )}
                    {userState.selectedOffice && availableDesks().length == 0 && (
                      <Box padding={3} justifyContent='center'>
                        <Typography variant='caption'>
                          No desks available, try a date range with fewer
                          consecutive dates or a different office.
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  {userState.officeDesks && userState.officeDesks.length > 0 && (
                    <Grid container maxWidth='sm'>
                      <Grid item xs={12} justifyContent='center'>
                        <Box
                          sx={{
                            marginTop: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                          }}
                        >
                          <Button
                            variant='contained'
                            onClick={confirmBookDesk}
                            disabled={busy}
                          >
                            Book selected
                          </Button>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box padding={2}>
                          {notifyUser != '' && (
                            <Alert
                              severity='success'
                              onClose={() => setNotifyUser('')}
                            >
                              {notifyUser}
                            </Alert>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={0} sm={0} md={1}></Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ marginTop: '15px' }}>
            <Box>
              <Box>
                {!!userState.selectedOffice &&
                  !!userState.bookings &&
                  !!userState.bookings.length && (
                    <>
                      <CalendarWeek
                        bookings={userState.bookings}
                        numOfDays={userState.selectedRecurAmount}
                        startDate={new Date(userState.selectedFromDate)}
                        uid={userState.user?.uid ?? ''}
                        deleteBooking={(forDate: Date) =>
                          deleteBooking(forDate)
                        }
                      ></CalendarWeek>
                    </>
                  )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
