import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import db from '../db/db'
import RsvpIcon from '@mui/icons-material/Rsvp'
import { Alert, Autocomplete, MenuItem, Select } from '@mui/material'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { sessionState } from '../state'
import theme from '../theme'
import { common } from '@mui/material/colors'

interface IInviteProps {
  onInvite: Function | undefined
}

export default function InviteSomeone(props: IInviteProps) {
  const [session] = useRecoilState(sessionState)
  const [showSuccess, setShowSuccess] = useState(false)

  const toggleSuccess = () => {
    setShowSuccess(!showSuccess)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    var email = data.get('email')?.toString() ?? ''

    const inviteData = {
      oid: session.selectedOffice?.oid,
      invitedOnDate: new Date(),
      invitedByUid: session.user?.uid ?? '',
      email: email
    }

    let emails = email.split(',')
    emails.forEach(async invited => {
      inviteData.email = invited.trim().toLowerCase()
      await db.addItem('invite', inviteData)
    })

    if (props.onInvite) {
      props.onInvite(inviteData)
    }

    toggleSuccess()
  }

  const officeOptions = () => {
    var items: JSX.Element[] = []

    session?.offices?.forEach(element => {
      items.push(
        <MenuItem value={element.oid} key={element.oid}>
          {element.name}
        </MenuItem>
      )
    })

    return items
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />{' '}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <RsvpIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Invite someone by email
        </Typography>
        <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete='invite-email'
                name='email'
                required
                fullWidth
                id='email'
                label='Invite email'
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                labelId='demo-simple-select-label'
                id='officeName'
                label='Select Office'
                fullWidth={true}
                value={session.selectedOffice?.oid}
              >
                {officeOptions()}
              </Select>
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            Send Invite
          </Button>
          <Box padding={2}>
            {showSuccess && (
              <Alert severity='success' onClose={toggleSuccess}>
                Person added to office
              </Alert>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
