import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import HomeIcon from '@mui/icons-material/Home'
import RsvpIcon from '@mui/icons-material/Rsvp'
import DateRangeIcon from '@mui/icons-material/DateRange'
import AddBoxIcon from '@mui/icons-material/AddBox'
import LogoutIcon from '@mui/icons-material/Logout'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import { sessionState } from '../state'
import { fixDate } from '../utils/FormatHelpers'
import initFirebase from '../db/initFirebase'
import { useNavigate } from 'react-router'
import { useRecoilState } from 'recoil'
import routes from '../pages/routes'
import db from '../db/db'
const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  })
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  })
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}))

export default function PersistentDrawerRight() {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const [session, setSession] = useRecoilState(sessionState)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const navigate = useNavigate()
  const handleSignOut = () => {
    initFirebase
      .getFireBaseStore()
      .auth.signOut()
      .then(
        function () {
          setSession({
            isAuthenticating: true,
            user: null,
            desks: [],
            offices: [],
            selectedOffice: undefined,
            officeDesks: [],
            selectedFromDate: fixDate(new Date()),
            selectedRecurUnit: 'Days',
            selectedRecurAmount: 5,
            bookings: [],
            userBookings: [],
            deskToEdit: null
          })
          navigate(routes.home, { replace: true })
        },
        function (error: any) {
          console.error('Sign Out Error', error)
        }
      )
  }

  const toggleDarkMode = async () => {
    const user = await db.query({
      collection: 'users',
      where: ['uid', '==', session.user?.uid]
    })

    if (user.length > 0) {
      const currentTheme = user[0].theme ? user[0].theme : 'light'
      const updatedUser = { ...user[0] }
      updatedUser.theme = currentTheme == 'light' ? 'dark' : 'light'
      setSession(old => ({ ...old, user: updatedUser }))

      await db.updateItem('users', user[0].id ?? '', updatedUser)
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar>
          {session.user?.theme == 'dark' ? (
            <img
              onClick={() => navigate(routes.home, { replace: true })}
              src='/logo-dark.png'
              alt='logo'
              style={{ width: 50, paddingBottom: 10, paddingRight: 4 }}
            />
          ) : (
            <img
              onClick={() => navigate(routes.home, { replace: true })}
              src='/logo.png'
              alt='logo'
              style={{ width: 50, paddingBottom: 10, paddingRight: 4 }}
            />
          )}
          <Typography
            variant='h6'
            noWrap
            sx={{ flexGrow: 1 }}
            component='div'
            onClick={() => navigate(routes.home, { replace: true })}
          >
            Meet for work portal
          </Typography>
          {session.user && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='end'
              onClick={handleDrawerOpen}
              sx={{ ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth
          }
        }}
        variant='persistent'
        anchor='right'
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {session.user && (
          <List>
            <ListItem
              key='home'
              disablePadding
              onClick={() => navigate(routes.home, { replace: true })}
            >
              <ListItemButton>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary='Home' />
              </ListItemButton>
            </ListItem>
            <ListItem
              key='book'
              disablePadding
              onClick={() => navigate(routes.book, { replace: true })}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DateRangeIcon />
                </ListItemIcon>
                <ListItemText primary='Book' />
              </ListItemButton>
            </ListItem>
            <ListItem
              key='desks'
              disablePadding
              onClick={() => navigate(routes.desks, { replace: true })}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary='Desks' />
              </ListItemButton>
            </ListItem>
            <ListItem
              key='invite'
              disablePadding
              onClick={() => navigate(routes.invite, { replace: true })}
            >
              <ListItemButton>
                <ListItemIcon>
                  <RsvpIcon />
                </ListItemIcon>
                <ListItemText primary='Invite' />
              </ListItemButton>
            </ListItem>
            <ListItem
              key='invite'
              disablePadding
              onClick={() => toggleDarkMode()}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DarkModeIcon />
                </ListItemIcon>
                <ListItemText primary='Toggle dark mode' />
              </ListItemButton>
            </ListItem>
          </List>
        )}
        <Divider />
        {session.user && (
          <List>
            <ListItem
              key='help'
              disablePadding
              onClick={() => navigate(routes.help, { replace: true })}
            >
              <ListItemButton>
                <ListItemIcon>
                  <HelpOutlineIcon />
                </ListItemIcon>
                <ListItemText primary='Help' />
              </ListItemButton>
            </ListItem>
            <ListItem
              key='signout'
              disablePadding
              onClick={() => navigate(routes.signin, { replace: true })}
            >
              <ListItemButton onClick={handleSignOut}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary='Sign out' />
              </ListItemButton>
            </ListItem>
          </List>
        )}
        <Divider />
        <List>
          <ListItem>
            <ListItemText primary='2023-01-10_20:13:34' />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  )
}
