import Wrapper from '../components/Wrapper'
import { useState } from 'react'
import Database from '../types/Database'
import initFirebase from '../db/initFirebase'
import InviteSomeone from '../components/InviteSomeone'
import PersistentDrawerRight from '../components/AppBarR'

const Invite = () => {
  const [LoggedInUser, setLoggedInUser] = useState<Database.User | undefined>(
    undefined
  )

  initFirebase
    .getFireBaseStore()
    .auth.onAuthStateChanged(function (user: Database.User | undefined | null) {
      if (user) {
        setLoggedInUser(user)
      }
    })

  return (
    <>
      <PersistentDrawerRight></PersistentDrawerRight>
      <Wrapper>
        {LoggedInUser && <InviteSomeone onInvite={() => {}}></InviteSomeone>}
      </Wrapper>
    </>
  )
}

export default Invite
