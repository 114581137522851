import {
  Box,
  Typography,
  TextField,
  Grid,
  ThemeProvider,
  Container,
  CssBaseline
} from '@mui/material'
import Wrapper from '../components/Wrapper'
import { useState } from 'react'
import Database from '../types/Database'
import Register from '../components/Register'
import initFirebase from '../db/initFirebase'
import PersistentDrawerRight from '../components/AppBarR'
import { useRecoilState } from 'recoil'
import {
  findExistingBookings,
  refreshBookingsForDate,
  sessionState
} from '../state'
import theme from '../theme'
import CalendarWeek from '../components/CalendarWeek'
import { fixDate } from '../utils/FormatHelpers'
import darkTheme from '../dark-theme'

const Home = () => {
  const [userState, setUserState] = useRecoilState(sessionState)

  const handleFromDayChange = async (e: any) => {
    if (!e.currentTarget) return
    console.log('here')
    const dt = new Date(e.currentTarget.value)

    setUserState(old => ({
      ...old,
      selectedFromDate: fixDate(dt)
    }))

    refreshBookingsForDate(setUserState, userState, dt)
  }

  const handleAmountChange = async (e: any) => {
    var num = parseInt(e.currentTarget.value)
    if (Number.isNaN(num)) return Promise.resolve()
    if (num < 1) return Promise.resolve()

    var dateRangeBookins = await findExistingBookings(
      new Date(userState.selectedFromDate),
      num,
      userState.selectedRecurUnit,
      userState.offices ?? []
    )

    console.log(dateRangeBookins)
    if (dateRangeBookins.length == 0) {
      setUserState(old => ({
        ...old,
        selectedRecurAmount: num
      }))
    } else {
      setUserState(old => ({
        ...old,
        selectedRecurAmount: num,
        bookings: dateRangeBookins
      }))
    }
  }

  return (
    <>
      <PersistentDrawerRight></PersistentDrawerRight>
      <Wrapper>{!userState.user && <Register></Register>}</Wrapper>
      <Wrapper>
        {userState.user && (
          <Container component='main' maxWidth='lg'>
            <Box component='form' noValidate>
              <Grid
                container
                spacing={1}
                sx={{
                  backgroundColor: 'secondary.light',
                  padding: '10px 10px 20px 5px',
                  border: '1px solid #efefef',
                  borderRadius: '10px',
                  boxShadow: '4px 2px 2px lightgrey',
                  mt: 3
                }}
              >
                <Grid item xs={12} sm={7}>
                  <Typography sx={{ color: 'text.primary' }}>From</Typography>
                  <TextField
                    type='date'
                    style={{ fontSize: '1.1rem' }}
                    fullWidth={true}
                    onChange={handleFromDayChange}
                    value={userState.selectedFromDate}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography sx={{ color: 'text.primary' }}>
                    For Days
                  </Typography>
                  <TextField
                    type='number'
                    fullWidth={true}
                    style={{ fontSize: '1.1rem' }}
                    onChange={handleAmountChange}
                    value={userState.selectedRecurAmount}
                  ></TextField>
                </Grid>
              </Grid>
            </Box>
            <Box paddingTop={3}>
              <CalendarWeek
                bookings={userState.bookings}
                uid={userState.user?.uid}
                startDate={new Date(userState.selectedFromDate)}
                numOfDays={userState.selectedRecurAmount}
                deleteBooking={null}
              ></CalendarWeek>
            </Box>
          </Container>
        )}
      </Wrapper>
    </>
  )
}

export default Home
