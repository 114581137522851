import { useEffect, useState } from "react";
import * as serviceWorkerRegistration from '../serviceWorkerRegistration'

export default function useServiceWorker(): { updateServiceWorker: () => void; newVersionAvailable: boolean; } {

 // Based on:
    // https://medium.com/toplyne-engineering/pwa-update-notifications-in-a-react-application-f5680d51bb2
    // and https://dev.to/daniellycosta/showing-new-version-available-notification-on-create-react-app-pwas-3jc9#important-things

    const [newVersionAvailable, setNewVersionAvailable] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>();

    function onServiceWorkerUpdate(registration: ServiceWorkerRegistration) {
        setWaitingWorker(registration.waiting);
        setNewVersionAvailable(true);
    }

    function updateServiceWorker() {
        console.log("Telling the service worker to skip waiting...");
        waitingWorker?.postMessage({ type: "SKIP_WAITING" });
        setNewVersionAvailable(false);
        window.location.reload();

    }

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            serviceWorkerRegistration.register({
                onUpdate: (r) => onServiceWorkerUpdate(r)
            });
        }
    }, [])

    return {updateServiceWorker, newVersionAvailable}

}