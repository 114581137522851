import Wrapper from '../components/Wrapper'
import initFirebase from '../db/initFirebase'
import React from 'react'
import { sessionState } from '../state'
import { useRecoilState } from 'recoil'
import OfficeDetail from '../components/OfficeDetail'
import PersistentDrawerRight from '../components/AppBarR'
import { NightShelter } from '@mui/icons-material'

const Book = () => {
  const [userState, setUserState] = useRecoilState(sessionState)

  const handleSignOut = (event: React.MouseEvent<HTMLButtonElement>) => {
    initFirebase
      .getFireBaseStore()
      .auth.signOut()
      .then(
        function () {
          setUserState({
            user: null,
            desks: undefined,
            offices: undefined,
            isAuthenticating: false,
            selectedOffice: userState.selectedOffice,
            selectedFromDate: '',
            selectedRecurAmount: 1,
            selectedRecurUnit: 'Days',
            bookings: [],
            officeDesks: [],
            userBookings: [],
            deskToEdit: null
          })
        },
        function (error: any) {
          console.error('Sign Out Error', error)
        }
      )
  }

  console.log('render')
  return (
    <>
      <PersistentDrawerRight></PersistentDrawerRight>
      <Wrapper>{userState.user && <OfficeDetail />}</Wrapper>
    </>
  )
}

export default Book
