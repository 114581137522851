const fixDate = (inputDate: Date) => {
  const htmldate = !!inputDate.getFullYear
    ? inputDate
    : (inputDate as any).toDate()
  const yyyy = htmldate.getFullYear()
  let mm = htmldate.getMonth() + 1 // Months start at 0!
  let dd = htmldate.getDate()
  let smm = mm.toString()
  let sdd = dd.toString()

  if (dd < 10) sdd = '0' + dd
  if (mm < 10) smm = '0' + mm

  return yyyy + '-' + smm + '-' + sdd
}

const dateMonth = (inputDate: Date) => {
  const htmldate = !!inputDate.getFullYear
    ? inputDate
    : (inputDate as any).toDate()

  return (
    htmldate.toLocaleString('default', { month: 'long' }) +
    ' ' +
    htmldate.getFullYear()
  )
}

const dayOfMonth = (inputDate: Date) => {
  const htmldate = !!inputDate.getFullYear
    ? inputDate
    : (inputDate as any).toDate()

  let dd = htmldate.getDate()

  return dd
}

const dateOnly = (htmldate: Date) => {
  return new Date(fixDate(htmldate))
}

const stringToColour = (str: string) => {
  let seed = str.trim();
  var hash = 0;
  for (var i = 0; i < seed.length; i++) {
    hash = seed.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    // Ensure the value is in the lighter range by adding a threshold
    var lightValue = (value % 128) + 128;
    colour += ('00' + lightValue.toString(16)).substr(-2);
  }
  return colour;
}

const getContrastYIQ = (hexcolor: string) => {
  hexcolor = hexcolor.replace('#', '')
  var r = parseInt(hexcolor.substr(0, 2), 16)
  var g = parseInt(hexcolor.substr(2, 2), 16)
  var b = parseInt(hexcolor.substr(4, 2), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

export { fixDate }
export { dayOfMonth }
export { dateMonth }
export { dateOnly }
export { stringToColour }
export { getContrastYIQ }
