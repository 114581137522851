import Wrapper from '../components/Wrapper'
import CreateDesk from '../components/CreateDesk'
import ListDesks from '../components/ListDesks'
import { getNewSession, sessionState } from '../state'
import { useRecoilState } from 'recoil'
import PersistentDrawerRight from '../components/AppBarR'
import db from '../db/db'
import Database from '../types/Database'
import { useNavigate } from 'react-router'
import routes from './routes'

const Desks = () => {
  const [userState, setUserState] = useRecoilState(sessionState)

  const reloadDesks = async () => {
    if (userState.user) {
      await getNewSession(
        setUserState,
        userState.user.uid ?? '',
        new Date(userState.selectedFromDate),
        userState.selectedRecurAmount,
        userState.selectedRecurUnit
      )
    }
  }

  const deleteDesk = async (id: any) => {
    console.log('delete desk', id)
    setUserState(old => ({
      ...old,
      desks: !userState?.desks
        ? []
        : [...userState.desks].filter(x => x.id != id)
    }))
    await db.deleteItem('desks', id)
    await reloadDesks()
  }

  const navigate = useNavigate()

  const selectDeskToEdit = async (desk: Database.Desk) => {
    navigate('/edit-desk/' + desk.id, { replace: true })
  }

  console.log('render')
  return (
    <>
      {/* <AppBar title='Meet for work portal' actions={[]} /> */}
      <PersistentDrawerRight></PersistentDrawerRight>
      <Wrapper>
        {userState.user && (
          <CreateDesk
            user={userState.user}
            onCreate={reloadDesks}
            onUpdate={() => {}}
            desk={userState.deskToEdit}
          />
        )}
        {userState.user && (
          <ListDesks
            uid={userState.user?.uid}
            desks={userState.desks}
            onDeskClicked={() => {}}
            onDeskDeleted={async (id: any) => await deleteDesk(id)}
            onDeskEdit={async (desk: Database.Desk) =>
              await selectDeskToEdit(desk)
            }
            selectedDesks={[]}
            readOnly={true}
            title='Your desks'
          />
        )}
      </Wrapper>
    </>
  )
}

export default Desks
