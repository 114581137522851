import { createTheme } from '@mui/material/styles'
import {
  grey,
  yellow,
  indigo,
  blueGrey,
  common,
  teal,
  blue
} from '@mui/material/colors'
import { color } from '@mui/system'

// https://mui.com/customization/theming/#themes

const theme = createTheme({
  // palette: {
  //   primary: primaryColor
  // }
  palette: {
    primary: {
      main: teal[500],
      dark: teal[400],
      light: teal[100]
    },
    secondary: {
      main: grey[100],
      dark: grey[400],
      light: grey[200]
    }
    // error: {

    // },
    // warning: {

    // },
    // info: {

    // },
    // success: {

    // }
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: { backgroundColor: teal[200] }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: blue[500],
          color: teal[800]
        },
        circular: {
          backgroundColor: yellow[500],
          color: teal[800]
        },
        colorDefault: {
          backgroundColor: teal[500],
          color: 'white'
        },
        img: {
          backgroundColor: yellow[500],
          color: teal[400]
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: { color: common.white },
        primary: { color: 'black', fontSize: '0.9rem', paddingRight: '15px' },
        secondary: {
          color: common.white,
          fontSize: '1.1rem',
          fontWeight: 'bolder',
          textAlign: 'center',
          borderRadius: '15px',
          width: '30px',
          padding: '3px 3px 3px 3px',
          backgroundColor: grey[700]
        }
      }
    }
  }
})

export default theme
