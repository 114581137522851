import { useCallback, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router'
import Signin from './Signin'
import Home from './Home'
import routes from './routes'
import { useRecoilState } from 'recoil'
import { getNewSession, sessionState } from '../state'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import Desks from './Desks'
import EditDesks from './EditDesks'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { dateOnly } from '../utils/FormatHelpers'
import Invite from './Invite'
import darkTheme from '../dark-theme'
import theme from '../theme'
import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { Backdrop, CircularProgress } from '@mui/material'
import { useLocation } from 'react-router'
import trackPathForAnalytics from '../utils/TrackPageForAnalytics'
import Book from './Book'
import EditOfficePage from '../components/EditOffice'
import PWAUpdater from '../components/PWAUpdater'

import HelpPage from './Help'
import ForgotPassword from './ForgotPassword'

const App = () => {
  const [session, setSession] = useRecoilState(sessionState)
  const [init, setInit] = React.useState<boolean>(true)

  const { pathname, search } = useLocation()

  const analytics = useCallback(() => {
    trackPathForAnalytics({
      path: pathname,
      search: search,
      title: pathname.split('/')[1]
    })
  }, [pathname, search])

  useEffect(() => {
    analytics()
  }, [analytics])

  useEffect(
    () =>
      onAuthStateChanged(getAuth(), async resultUser => {
        if (resultUser) {
          await getNewSession(
            setSession,
            resultUser?.uid ?? '',
            dateOnly(new Date()),
            5,
            'Days'
          )
        }
        console.log('user state changes', resultUser)
        setInit(false)
      }),
    [setSession]
  )

  return (
    <ThemeProvider theme={session.user?.theme == 'light' ? theme : darkTheme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={init}
        onClick={() => {}}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <PWAUpdater>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Routes>
            <Route path={routes.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes.signin} element={<Signin />} />
            <Route path={routes.home} element={<Home />} />
            <Route path={routes.desks} element={<Desks />} />
            <Route path={routes.book} element={<Book />} />
            <Route path={routes.invite} element={<Invite />} />
            <Route path={routes.editDesk} element={<EditDesks />} />
            <Route path={routes.editOffice} element={<EditOfficePage />} />
            <Route path='*' element={<Navigate to={routes.home} />} />
            <Route path={routes.help} element={<HelpPage />} />
          </Routes>
        </LocalizationProvider>
      </PWAUpdater>
    </ThemeProvider>
  )
}

export default App
