import React from "react";
import useServiceWorker from "../utils/useServiceWorker.hook";
import { Button, SnackbarContent, Snackbar } from '@mui/material';

const UpdatesAvailableToast = (props: { onUpdateClicked: () => void }) => {
    const action = (
        <Button color="primary" size="small" onClick={() => props.onUpdateClicked()}>
            reload now 
        </Button>
    );
    return (
        <Snackbar open={true} anchorOrigin={{vertical: "bottom", horizontal: "right"}} >
            <SnackbarContent message="An update is available." action={action} />
        </Snackbar>
    );
}

export default function PWAUpdater(props: React.PropsWithChildren<{}>) {

    const { updateServiceWorker, newVersionAvailable } = useServiceWorker();


    return (
        <>

            {
                (newVersionAvailable) ? <UpdatesAvailableToast onUpdateClicked={updateServiceWorker} /> : <></>
            }
            {
                props.children
            }
        </>
    );
}
