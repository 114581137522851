/**
 * Define the different parts of your state here
 * using https://recoiljs.org/
 */
import { atom, SetterOrUpdater, useSetRecoilState } from 'recoil'
import Database from './types/Database'
import db from './db/db'
import { SettingsAccessibilityOutlined } from '@mui/icons-material'
import { dateOnly, fixDate } from './utils/FormatHelpers'
import { collection } from 'firebase/firestore'
import initFirebase from './db/initFirebase'

export interface ISessionState {
  user: Database.User | null
  isAuthenticating: boolean
  desks: Database.Desk[] | undefined
  offices: Database.Office[] | undefined
  selectedOffice: Database.Office | undefined
  officeDesks: Database.Desk[] | undefined
  selectedFromDate: string
  selectedRecurAmount: number
  selectedRecurUnit: 'Days' | 'Recur Weekly'
  bookings: Database.Booking[] | undefined
  userBookings: Database.Booking[] | undefined
  deskToEdit: Database.Desk | null
}

export const sessionState = atom<ISessionState>({
  key: 'session',
  default: {
    isAuthenticating: true,
    user: null,
    desks: [],
    offices: [],
    selectedOffice: undefined,
    officeDesks: [],
    selectedFromDate: fixDate(new Date()),
    selectedRecurAmount: 1,
    selectedRecurUnit: 'Days',
    bookings: [],
    userBookings: [],
    deskToEdit: null
  }
})

const dateRangeDates = (
  startDate: Date,
  unit: 'Days' | 'Recur Weekly',
  num: number
) => {
  var increments = unit == 'Days' ? 1 : 7
  const dateList: Date[] = []
  for (let index = 0; index < num; index++) {
    const forDate = dateOnly(new Date(startDate))
    forDate.setDate(forDate.getDate() + index * increments)
    dateList.push(forDate)
  }
  return dateList
}

const refreshBookingsForDate = async (
  setState: SetterOrUpdater<ISessionState>,
  currentState: ISessionState,
  newDate: Date
) => {
  var dateRangeBookins = await findExistingBookings(
    newDate,
    currentState.selectedRecurAmount,
    currentState.selectedRecurUnit,
    currentState.offices ?? []
  )

  setState(old => ({
    ...old,
    selectedFromDate: fixDate(newDate),
    bookings: dateRangeBookins
  }))
}

const findExistingBookings = async (
  selectedFromDate: Date,
  number: number,
  unit: 'Days' | 'Recur Weekly',
  myOffices: Database.Office[]
) => {
  var Difference_In_Days = unit == 'Days' ? number : 10

  // To calculate the no. of days between two dates
  if (Difference_In_Days < 0) return []

  const dateRange: Date[] = dateRangeDates(selectedFromDate, unit, number)
  const minDate = dateRange.reduce((a, b) => (a < b ? a : b))

  if (myOffices.length <= 0) {
    return []
  }

  var bookings = await db.query({
    collection: 'booking',
    where: [
      ['forDate', '>=', minDate],
      ['oid', 'in', myOffices.map(x => x.oid)]
    ]
  })

  return bookings
}

const myDesks = async (uid: string | undefined) => {
  if (!uid) return

  return await db.query({
    collection: 'desks',
    where: ['uid', '==', uid]
  })
}

const meUser = async (uid: string | undefined) => {
  if (!uid) return

  return await db.query({
    collection: 'users',
    where: ['uid', '==', uid]
  })
}

const myOffices = async (user: Database.User | undefined) => {
  if (!user) return

  var invites = await db.query({
    collection: 'invite',
    where: ['email', '==', user.email]
  })

  if (!user.officeids || (user.officeids.length == 0 && invites.length == 0))
    return []

  var myCreatedOffices =
    user.officeids.length > 0
      ? await db.query({
          collection: 'offices',
          where: ['oid', 'in', user.officeids]
        })
      : []

  var invitedToOffices =
    invites.length > 0
      ? await db.query({
          collection: 'offices',
          where: ['oid', 'in', invites.map(x => x.oid)]
        })
      : []

  return myCreatedOffices.concat(invitedToOffices)
}

const myBookings = async (uid: string): Promise<Database.Booking[]> => {
  return await db.query({
    collection: 'booking',
    where: ['uid', '==', uid]
  })
}

const officeDesks = async (office: Database.Office) => {
  return await db.query({
    collection: 'desks',
    where: ['office', '==', office.oid]
  })
}

const getNewSession = async (
  setSession: SetterOrUpdater<ISessionState>,
  uid: string,
  from: Date,
  amount: number,
  unit: 'Days' | 'Recur Weekly'
) => {
  var desks = await myDesks(uid)
  var user = await meUser(uid)
  var foundUser = user ? user[0] : undefined
  var offices = await myOffices(foundUser)
  var selectedOfficeDesks: Database.Desk[] =
    offices && !!offices.length ? await officeDesks(offices[0]) : []
  var bookings = await findExistingBookings(from, amount, unit, offices ?? [])
  var userBookings: Database.Booking[] = await myBookings(uid)

  const newSession = {
    isAuthenticating: false,
    user: user ? user[0] : null,
    desks: desks,
    offices: offices,
    selectedOffice: offices?.length ? offices[0] : undefined,
    officeDesks: selectedOfficeDesks,
    bookings: bookings,
    selectedFromDate: fixDate(from),
    selectedRecurAmount: amount,
    selectedRecurUnit: unit,
    userBookings: userBookings,
    deskToEdit: null
  }
  setSession(newSession)
  return newSession
}

export { getNewSession }
export { officeDesks }
export { findExistingBookings }
export { refreshBookingsForDate }
export { dateRangeDates }
