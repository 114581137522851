import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  connectFirestoreEmulator,
  enableMultiTabIndexedDbPersistence
} from 'firebase/firestore'
import {
  getAuth,
  connectAuthEmulator
  // signInWithCredential,
  // EmailAuthProvider
} from 'firebase/auth'

// the values to initialize the firebase app can be found in your firebase project
const firebaseConfig = {
  apiKey: "AIzaSyDN_Aaz-VfNlb_R-JD1SXJxg2ljOLgVpf0",
  authDomain: "hotdesk-34d9a.firebaseapp.com",
  projectId: "hotdesk-34d9a",
  storageBucket: "hotdesk-34d9a.appspot.com",
  messagingSenderId: "865774902161",
  appId: "1:865774902161:web:d4c25bf216e3478dc75830",
  measurementId: "G-RLVLDZFT8J"
};

var firebase: any = {};

const getFireBaseStore = () => {
  return firebase;
}

const initFirebase = async () => {
  try {
    firebase.app = initializeApp(firebaseConfig)
    firebase.firestore = getFirestore(firebase.app)
    firebase.auth = getAuth(firebase.app)
    

    if (process.env.NODE_ENV !== 'production') {
      connectAuthEmulator(firebase.auth, 'http://localhost:9099')
      connectFirestoreEmulator(firebase.firestore, 'localhost', 8080)
      enableMultiTabIndexedDbPersistence(firebase.firestore)

      
      /**
       * The following code logins the user automatically to speed up development.
       * For this to work you first need to create a user and then run the command
       * yarn emulator:export, then import the data when starting the emulator
       * yarn firebase emulators:start --only firestore,auth --import=firestore_mock_data
       */
      // signInWithCredential(
      //   auth,
      //   EmailAuthProvider.credential('john@doe.com', '123123')
      // )
    }
  } catch (err) {
    console.error(err)
    return err
  }
}

export default  {
  init: initFirebase,
  getFireBaseStore: getFireBaseStore
}

