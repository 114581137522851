import { createTheme } from '@mui/material/styles'
import {
  grey,
  yellow,
  lime,
  indigo,
  blueGrey,
  common,
  teal,
  blue
} from '@mui/material/colors'
import { color } from '@mui/system'
import { isJSDocCommentContainingNode } from 'typescript'
import { Commit, CommitOutlined, Padding } from '@mui/icons-material'

// https://mui.com/customization/theming/#themes

const darkTheme = createTheme({
  // palette: {
  //   primary: primaryColor
  // }
  palette: {
    primary: {
      main: blueGrey[400],
      dark: blueGrey[500],
      light: blueGrey[300]
    },
    secondary: {
      main: blueGrey['A400'],
      dark: blueGrey['A700'],
      light: blueGrey['A100']
    },
    background: {
      default: grey[800]
    },
    text: {
      primary: grey[900]
    }

    // error: {

    // },
    // warning: {

    // },
    // info: {

    // },
    // success: {

    // }
  },
  typography: {
    body2: {
      color: common.white
    },
    allVariants: {
      color: grey[100]
    },
    h6: {
      color: grey[900]
    },
    subtitle1: {
      color: 'red'
    },
    subtitle2: {
      color: 'blue'
    },
    caption: {
      color: common.white
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'primary.dark',
          borderBlockColor: 'white',
          border: '1px solid #555',
          backgroundColor: 'secondary.light'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: common.black
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: grey[500],
          borderBlockColor: grey[500],
          backgroundColor: grey[100]
        }
      }
    },
    // MuiContainer: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "white", padding: '10px 10px 20px 5px', border: '1px solid #efefef', borderRadius: '10px', boxShadow: '10px 5px 5px lightgrey',
    //     },

    // }
    // },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '7px'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: teal[400]
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        selected: {
          backgroundColor: common.white
        },
        disabled: {
          backgroundColor: common.white
        },
        root: {
          backgroundColor: common.black,
          color: common.white,
          '&:focus': {
            // this is to refer to the prop provided by M-UI
            backgroundColor: 'black !important' // updated backgroundColor
          },
          '&:hover': {
            // this is to refer to the prop provided by M-UI
            backgroundColor: teal[400] // updated backgroundColor
          },
          '&:active': {
            // this is to refer to the prop provided by M-UI
            backgroundColor: 'black !important' // updated backgroundColor
          },
          '&.Mui-selected': {
            // this is to refer to the prop provided by M-UI
            backgroundColor: 'black !important', // updated backgroundColor
            color: 'white'
          },
          '&.Mui-selected:hover': {
            // this is to refer to the prop provided by M-UI
            backgroundColor: '#26a69a !important', // updated backgroundColor
            color: 'white'
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        focusVisible: {
          color: 'black'
        },
        root: {
          color: 'black',
          minHeight: '50px'
        },
        disabled: {
          color: 'black'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'grey'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: { color: 'black' }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: { backgroundColor: 'grey' }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: 'primary.light',
          fontSize: '0.9rem',
          paddingRight: '15px'
        },
        secondary: {
          color: common.white,
          fontSize: '1.1rem',
          fontWeight: 'bolder',
          textAlign: 'center',
          borderRadius: '15px',
          width: '30px',
          padding: '3px 3px 3px 3px',
          backgroundColor: grey[800]
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: grey[500],
          color: grey[800]
        },
        circular: {
          backgroundColor: grey[500],
          color: grey[800]
        },
        colorDefault: {
          backgroundColor: grey[500],
          color: 'white'
        },
        img: {
          backgroundColor: grey[500],
          color: grey[400]
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          color: 'white !important'
        }
      }
    }
  }
})

export default darkTheme
