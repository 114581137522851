import Wrapper from '../components/Wrapper'
import { useState } from 'react'
import Database from '../types/Database'
import initFirebase from '../db/initFirebase'
import InviteSomeone from '../components/InviteSomeone'
import PersistentDrawerRight from '../components/AppBarR'
import { Box, Container, Typography } from '@mui/material'

const HelpPage = () => {
  const [LoggedInUser, setLoggedInUser] = useState<Database.User | undefined>(
    undefined
  )

  initFirebase
    .getFireBaseStore()
    .auth.onAuthStateChanged(function (user: Database.User | undefined | null) {
      if (user) {
        setLoggedInUser(user)
      }
    })

  return (
    <>
      <PersistentDrawerRight></PersistentDrawerRight>
      <Wrapper>
        <Container component='main' maxWidth='sm'>
          <Box
            sx={{
              marginTop: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left'
            }}
          >
            <Typography component='h1' fontSize={20}>
              Meet for work offers a super simple hot desking solution
            </Typography>
            <br></br>
            <Typography component='h2' fontWeight={700}>
              Step 1 - Create some desks
            </Typography>
            <Typography component='h2' fontWeight={100}>
              Make sure you are logged in with the email who will own and manage
              the desks going forward. Go to the DESKS menu and start adding
              your desks. The office will get created when you create a desk in
              a office that doesn't exist yet.
            </Typography>
            <br></br>
            <Typography component='h2' fontWeight={700}>
              Step 2 - Invite some colleagues
            </Typography>
            <Typography component='h2' fontWeight={100}>
              Go to the INVITE menu, select the office and enter the email
              address of someone you want to be able to book desks in your
              office. Tell that person to register at meetforwork using the same
              email address you invited them with.
            </Typography>
            <br></br>
            <Typography component='h2' fontWeight={700}>
              Step 3 - Book a desk
            </Typography>
            <Typography component='h2' fontWeight={100}>
              Go to the BOOK menu. Select the day, how many days and select the
              office and desk you want to book for and click book. You can
              delete your own bookings on this same page.
            </Typography>
            <br></br>
            <Typography component='h2' fontWeight={700}>
              Step 4 - See who is coming to work on any given day
            </Typography>
            <Typography component='h2' fontWeight={100}>
              On the home screen you can see the next few days and who is booked
              in. You can hover over their names on desktop to see what desk
              they booked.
            </Typography>
          </Box>
        </Container>
      </Wrapper>
    </>
  )
}

export default HelpPage
