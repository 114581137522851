import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import initFirebase from '../db/initFirebase'
import { Firestore } from 'firebase/firestore'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import db from '../db/db'
import theme from '../theme'
import {
  Alert,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import darkTheme from '../dark-theme'

function Copyright(props: any) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit' href='https://mui.com/'>
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default function SignUp() {
  const [authError, setAuthError] = React.useState<string>('')
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const auth = initFirebase.getFireBaseStore().auth
    const store: Firestore = initFirebase.getFireBaseStore().firestore
    var email = data.get('email')?.toString() ?? ''
    var password = data.get('password')?.toString() ?? ''
    var allowEmail = (data.get('allowExtraEmails')?.toString() ?? '') == 'true'
    createUserWithEmailAndPassword(auth, email, password)
      .then(async userCredential => {
        // Signed in
        const user = userCredential.user
        // Add a new document in collection "cities"
        console.log('Adding user', user)

        db.addItem('users', {
          email: user.email,
          uid: user.uid,
          firstname: data.get('firstName')?.toString(),
          lastname: data.get('lastName')?.toString(),
          marketing: allowEmail,
          officeids: [],
          theme: myTheme
        })
        console.log('Done Adding user')
        // ...
      })
      .catch(error => {
        var errorCode = error.code.toString().substring(5)
        if (errorCode == 'weak-password')
          errorCode = 'Password needs to be at least 6 characters'
        setAuthError(errorCode)
        // ..
      })
  }

  const [myTheme, setTheme] = React.useState<string>('dark')
  const handleSetTheme = (event: SelectChangeEvent) => {
    setTheme(event.target.value as string)
  }

  return (
    <ThemeProvider theme={myTheme == 'light' ? theme : darkTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign up
          </Typography>
          <Box
            component='form'
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete='given-name'
                  name='firstName'
                  required
                  fullWidth
                  id='firstName'
                  label='First Name'
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id='lastName'
                  label='Last Name'
                  name='lastName'
                  autoComplete='family-name'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='new-password'
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id='demo-simple-select-label'>Theme</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Age'
                  value={myTheme}
                  onChange={handleSetTheme}
                >
                  <MenuItem value={'light'}>Light</MenuItem>
                  <MenuItem value={'dark'}>Dark</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Box padding={2}>
              {authError != '' && (
                <Alert severity='error' onClose={() => setAuthError('')}>
                  {authError}
                </Alert>
              )}
            </Box>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Link href='/signin' variant='body2' sx={{ color: '#0000dd' }}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )
}
