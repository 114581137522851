const routes = {
  home: '/',
  signin: '/signin',
  desks: '/desks',
  book: '/book',
  bookFor: '/book/:forDate',
  invite: '/invite',
  editDesk: '/edit-desk/:id',
  editOffice: '/edit-office/:id',
  help: '/help',
  forgotPassword: '/forgotpassword'
}

export default routes
