import routes from './routes'
import Wrapper from '../components/Wrapper'
import SignIn from '../components/Signup'
import initFirebase from '../db/initFirebase'
import { useState } from 'react'
import Database from '../types/Database'
import { Navigate } from 'react-router-dom'
import PersistentDrawerRight from '../components/AppBarR'

const Signin = () => {
  const [LoggedInUser, setLoggedInUser] = useState<Database.User | undefined>(
    undefined
  )

  initFirebase
    .getFireBaseStore()
    .auth.onAuthStateChanged(function (user: Database.User | undefined | null) {
      if (user) {
        setLoggedInUser(user)
      }
    })

  return (
    <>
      <PersistentDrawerRight></PersistentDrawerRight>
      <Wrapper>
        {!LoggedInUser && <SignIn></SignIn>}
        {LoggedInUser && <Navigate to={routes.home} />}
      </Wrapper>
    </>
  )
}

export default Signin
