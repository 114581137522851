import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import {
  Chip,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack
} from '@mui/material'
import Database from '../types/Database'
import {
  dateOnly,
  fixDate,
  stringToColour,
  getContrastYIQ,
  dayOfMonth,
  dateMonth
} from '../utils/FormatHelpers'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import DeleteIcon from '@mui/icons-material/Delete'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router'
import { refreshBookingsForDate, sessionState } from '../state'
import { datePickerValueManager } from '@mui/x-date-pickers/DatePicker/shared'

interface IBookingProps {
  bookings: Database.Booking[] | undefined
  uid: string | undefined
  startDate: Date
  numOfDays: number
  deleteBooking: Function | null
}

export default function CalendarWeek(props: IBookingProps) {
  const navigate = useNavigate()
  console.log(props)
  const addDays = (startDate: Date, num: number): Date => {
    const forDate = dateOnly(new Date(startDate))
    forDate.setDate(forDate.getDate() + num)
    return forDate
  }

  const weekDay = (startDate: Date, num: number): string => {
    const forDate = addDays(startDate, num)
    const dow = forDate.toLocaleString('en', { weekday: 'long' })
    return dow.substring(0, 3)
  }

  const bookingsForDay = (date: Date) => {
    return props.bookings?.filter(x => {
      if (!x.forDate) return false

      const d: any = x.forDate
      return (d.seconds ?? d.getTime() / 1000) == date.getTime() / 1000
    })
  }

  const arrayListForDays = () => {
    const dayItems: number[] = []

    for (let i = 0; i < props.numOfDays; i++) {
      dayItems.push(i)
    }
    return dayItems
  }
  const [userState, setUserState] = useRecoilState(sessionState)
  const bookForDate = (d: Date) => {
    refreshBookingsForDate(setUserState, userState, d)
    navigate('/book', { replace: true })
  }

  const deleteClick = (deleteBookingsForDate: Date) => {
    if (props.deleteBooking) {
      props.deleteBooking(deleteBookingsForDate)
    }
  }

  return (
    <Box>
      <List
        dense={true}
        sx={{
          backgroundColor: 'secondary.light',
          border: '1px solid #efefef',
          padding: '5px 10px 5px 5px',
          borderRadius: '5px',
          boxShadow: '4px 2px 2px lightgrey'
        }}
      >
        {arrayListForDays().map(x => {
          const startDateOnly = dateOnly(props.startDate)
          const dow: string = weekDay(startDateOnly, x)
          const dom: string = dayOfMonth(addDays(startDateOnly, x))
          const bkCol =
            dow == 'Sun' || dow == 'Sat' ? 'secondary.light' : 'primary.light'
          const dayBookings = bookingsForDay(addDays(startDateOnly, x))

          const dateItem = (
            <ListItem
              sx={[
                {
                  backgroundColor: 'secondary.main',
                  margin: '2px',
                  borderRadius: '5px'
                },
                { '&:hover': { backgroundColor: 'primary.main' } }
              ]}
              key={'day' + x}
              secondaryAction={
                props.deleteBooking &&
                dayBookings?.find(x => x.uid == userState.user?.uid) && (
                  <IconButton
                    title='Cancel my booking'
                    edge='end'
                    aria-label='delete'
                    onClick={() => deleteClick(addDays(startDateOnly, x))}
                  >
                    <DeleteIcon />
                  </IconButton>
                )
              }
            >
              <Grid container>
                <Grid item xs={2} sm={1} md={1} className='black'>
                  <Box>
                    <ListItemText
                      primary={weekDay(startDateOnly, x)}
                      secondary={dom}
                    ></ListItemText>
                  </Box>
                </Grid>
                <Grid item xs={10} sm={11} md={11} className='black'>
                  <ListItemButton
                    component='a'
                    href='#simple-list'
                    onClick={() => bookForDate(addDays(startDateOnly, x))}
                  >
                    <Box>
                      {dayBookings?.map(y => (
                        <Chip
                          label={y.bookerName}
                          title={y.officeName + ' - ' + y.deskName}
                          about={y.bookingKey}
                          key={y.bookingKey}
                          sx={{
                            backgroundColor: stringToColour(y.bookerName ?? ''),
                            color: getContrastYIQ(
                              stringToColour(y.bookerName ?? '')
                            ),
                            margin: '1px'
                          }}
                        />
                      ))}
                      {/* <Chip
                    label={"Ben P"}
                    sx={{
                      backgroundColor: stringToColour("red"),
                      color: getContrastYIQ(stringToColour("red")),
                      margin: "1px",
                    }}
                  />
                  <Chip
                    label={"Ben P"}
                    sx={{
                      backgroundColor: stringToColour("red"),
                      color: getContrastYIQ(stringToColour("red")),
                      margin: "1px",
                    }}
                  />
                  <Chip
                    label={"Ben P"}
                    sx={{
                      backgroundColor: stringToColour("red"),
                      color: getContrastYIQ(stringToColour("red")),
                      margin: "1px",
                    }}
                  /> */}
                    </Box>
                  </ListItemButton>
                </Grid>
              </Grid>
            </ListItem>
          )

          return dom == '1' || x == 0 ? (
            <i>
              <ListItem
                sx={[
                  {
                    backgroundColor: 'secondary.main',
                    margin: '2px',
                    textAlign: 'center',
                    borderRadius: '5px 5px'
                  }
                ]}
              >
                <ListItemText
                  primary={dateMonth(addDays(startDateOnly, x))}
                ></ListItemText>
              </ListItem>{' '}
              {dateItem}
            </i>
          ) : (
            dateItem
          )
        })}
      </List>
    </Box>
  )
}
