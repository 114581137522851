import React from 'react'
import { useRecoilState } from 'recoil'
import { sessionState } from '../state'

export default function EditOffice() {
  const [userState, setUserState] = useRecoilState(sessionState)
  const [busy, SetBusy] = React.useState<boolean>(false)

  return (
    <>
      <div>Offices</div>
    </>
  )
}
